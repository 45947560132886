/*
 * @Author: alanChen
 * @Date: 2021-11-26 21:23:31
 * @LastEditTime: 2021-11-28 22:12:59
 * @LastEditors: your name
 * @Description: 示例
 * @FilePath: \project\src\App.tsx
 * tel:15584312340/mail:15584312340@163.com
 */
import {
  computed,
  createApp,
  defineComponent,
  h,
  onMounted,
  reactive,
  ref,
  watchEffect,
  provide,
} from "vue";
import "./App.less";
export default defineComponent({
  setup() {
    return () => {
      return (
        <div class="layOut_01">
          <router-view />
        </div>
      );
    };
  },
});
