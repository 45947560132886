/*
 * @Author: your name
 * @Date: 2021-09-13 09:39:21
 * @LastEditTime: 2022-01-12 15:01:32
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \project\src\main.ts
 */
import { createApp } from "vue";
import $ from "jquery";
import App from "./App";
import "./registerServiceWorker";
import router from "./router";
import ElementPlus from 'element-plus'
import * as icons from '@element-plus/icons'
import 'element-plus/dist/index.css'
import '../src/common/style/ElementCommon.less'
import '../src/common/fontsIcon/iconfont.css'

import "../src/common/style/CommonLayOut.less";
import AES_CBC from "./util/encryption";
import store from "./store";
import CommonFun from "@/common/ts/commonFun"
import installMaxerStore, { Maxer } from "./store/maxer.mixin";
import initStorePersistence from "./store/store.persistence";
import * as echarts from 'echarts'
import Bus from "@/util/bus"; /// mitt 总线程引入
// 声明全局组件  优化VUEX的使用方式
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $m: Maxer; // 声明全局方法
  }
}



const app: any = createApp(App);
installMaxerStore(app); // 全局混入vuex
initStorePersistence(store); // 初始化持久化vuex
Object.keys(icons).forEach((key: string) => {
  app.component(key, icons[key])
})
// 屏蔽错误信息
// app.config.errorHandler = () => null;
// 屏蔽警告信息
app.config.warnHandler = () => null;
app.config.globalProperties.echarts = echarts; // 全局挂在Echarts
app.config.globalProperties.$AES_CBC = AES_CBC; // 全局挂载AES加密
app.config.globalProperties.$Bus = Bus; // 全局挂在中央数据总线
app.config.globalProperties.$CommonFun = CommonFun; // 挂在全局方法
app.use(store).use(router).use(ElementPlus).mount("#app");

export default app;

// 获取高德地图配置信息
function FnGetWebGisSetting() {
  console.log("FnGetWebGisSetting: 获取高德地图配置信息");
  $.ajax({
    url: "./json/webGis.json",
    async: false,
    success(data: any) {
      console.log("FnGetWebGisSetting: data ====>", data);
      app.config.globalProperties.$m.vuex("vuexWebGisSetting", data);
    },
  });
}
FnGetWebGisSetting();

// 获取服务路径并保存
function FnGetServerPath() {
  console.log("FnGetServerPath: 获取服务路径并保存");
  $.ajax({
    url: "./json/publishObjectPath.json",
    async: false,
    success(data: any) {
      console.log("FnGetServerPath: data ====>", data);
      app.config.globalProperties.$m.vuex("vuexServePath", data);
    },
  });
}
FnGetServerPath();
