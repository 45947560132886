/*
 * @Author: alanChen
 * @Date: 2021-11-23 16:16:05
 * @LastEditTime: 2021-11-26 21:42:47
 * @LastEditors: your name
 * @Description: 公共方法
 * @FilePath: \project\src\common\ts\commonFun.ts
 * tel:15584312340/mail:15584312340@163.com
 */
import { ElMessage } from "element-plus";
import AES_CBC from "@/util/encryption";
import { useRouter } from "vue-router"; //引入路由

const router = useRouter();
export default {
  // 接口报错后记录报错信息，并且返回错误提示
  FnCommonMsg(
    res: string,
    messageType: "success" | "warning" | "info" | "error"
  ) {
    ElMessage({
      message: res,
      type: messageType,
    });
  },
};
