// AES 加密方法

import CryptoJS from "crypto-js";
const key = CryptoJS.enc.Latin1.parse("123456781234567812345678");
const iv = CryptoJS.enc.Latin1.parse("1234567812345678");
export default {
  // 加密
  encrypt(word: any) {
    const encoded = CryptoJS.AES.encrypt(word, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      adding: CryptoJS.pad.ZeroPadding,
    }).toString();
    return encoded;
  },
  // 解密
  decrypt(word: any) {
    const decoded = CryptoJS.AES.decrypt(word, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      adding: CryptoJS.pad.ZeroPadding,
    }).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decoded);
  },
};
