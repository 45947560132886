/*
 * @Author: alanChen
 * @Date: 2021-11-23 16:16:05
 * @LastEditTime: 2022-01-13 18:26:18
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \project\src\router\index.ts
 * tel:15584312340/mail:15584312340@163.com
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store/index";
import Bus from "@/util/bus"; /// mitt 总线程引入
import { ElMessage } from "element-plus";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/Login.vue"),
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("../views/main/Main.vue"),
    children: [
      {
        path: "/demoView",
        name: "DemoView",
        component: () => import("../views/feature/demoView/DemoView.vue"),
      },
      {
        path: "/appList",
        name: "应用列表",
        component: () => import("../views/feature/appList/AppList.vue"),
      },
      {
        path: "/addApp",
        name: "添加应用",
        component: () => import("../views/feature/addApp/AddApp.vue"),
      },
      {
        path: "/classList",
        name: "应用分类",
        component: () => import("../views/feature/classList/ClassList.vue"),
      },
      {
        path: "/appContent",
        name: "应用内容",
        component: () => import("../views/feature/appContent/AppContent.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "HomePage",
    component: () => import("../views/website/homePage/HomePage.vue"),
  },
  {
    path: "/webGis",
    name: "WebGis",
    component: () => import("../views/webGis/WebGis.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to: any, from) => {
  console.log("to ====>", to);
  console.log("from ====>", from);
  if (to.matched[0].name === "Main") {
    console.log("进入数据管理系统需要先通过路由守卫:");
    const time = new Date();
    const timestamp: any = Math.round((time as any) / 1000);
    console.log("允许访问时间，当前时间", store.state.vuexUpdataTime, timestamp);
    if (timestamp < store.state.vuexUpdataTime) {
      return true;
    } else {
      ElMessage.warning({
        message: "当前访问已超时, 请重新登录",
        type: "warning",
      });
      return false;
    }
  } else {
    console.log("不进入数据管理系统不需要路由守卫。");
  }
});

export default router;
