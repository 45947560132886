/*
 * @Author: alanChen
 * @Date: 2021-12-02 10:18:42
 * @LastEditTime: 2022-01-13 18:55:24
 * @LastEditors: your name
 * @Description: 
 * @FilePath: \project\src\store\index.ts
 * tel:15584312340/mail:15584312340@163.com
 */
import { createStore } from "vuex";

export default createStore({
  state: {
    vuexConfigInfo:'', // 配置信息
    vuexUserInfo:'', // 用户信息
    vuexWebGisSetting: '', // webgis配置信息
    vuexServePath: '', // 服务路径
    vuexUpdataTime: '', // 更新允许操作时间
    routeStore: {}, // 路由跳转所带参数
  },
  mutations: {
    $changeStore(state: any, payload: any) {
      // 判断是否为多层级调用，state中为对象存在的情况，诸如user.info.name = 'xxx'
      const nameArr = payload.name.split(".");
      const len = nameArr.length;
      if (len >= 2) {
        let obj = state[nameArr[0]];
        for (let i = 1; i < len - 1; i++) {
          obj = obj[nameArr[i]];
        }
        obj[nameArr[len - 1]] = payload.value;
      } else {
        state[payload.name] = payload.value;
      }
    },
  },
});
